import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import SimilarExercises from "../components/SimilarExercises";
import Detail from "../components/Detail";
import exercisesJson from "../assets/data/exercises.json";

const ExerciseDetail = () => {
  const [exerciseDetail, setExerciseDetail] = useState({});
  const [targetMuscleExercises, setTargetMuscleExercises] = useState({});
  const [equipmentExercises, setEquipmentExercises] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchExercisesData = async () => {
      const exerciseDetailData = exercisesJson.find((item) => item.id === id);

      const targetMuscleExerciseslData = exercisesJson.filter(
        (item) => item.target === exerciseDetailData.target
      );

      const equipmentExerciseslData = exercisesJson.filter(
        (item) => item.equipment === exerciseDetailData.equipment
      );

      setExerciseDetail(exerciseDetailData);
      setTargetMuscleExercises(targetMuscleExerciseslData);
      setEquipmentExercises(equipmentExerciseslData);
    };

    fetchExercisesData();
  }, [id]);
  return (
    <Box sx={{ mt: { lg: "96px", xs: "60px" } }}>
      <Detail exerciseDetail={exerciseDetail} />
      {/* <ExerciseVideos/> */}
      <SimilarExercises
        targetMuscleExercises={targetMuscleExercises}
        equipmentExercises={equipmentExercises}
      />
    </Box>
  );
};

export default ExerciseDetail;
